<template>
  <div>
    <div class="card">
      <div style="background: #DBE7F2" class="p-2">
        <TitleButton
          btnTitle="Add New"
          :showBtn="true"
          :showSettingBtn="false"
          :showAddNew="false"
          :title="headerTitle"
          @onClickSettingButton="onClickSettingButton"
          @onClickCloseButton="onClickCloseButton"
        />

        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-sm-3">
              <label class="align-middle">Supplier</label>
              <v-select
                placeholder="Select Supplier type"
                v-model="formData.supplier_id"
                :options="contacts"
                label="name"
                :reduce="name => name.id"
                :disabled="!shouldSave"
              />
            </div>
            <div class="col-sm-3">
              <label class="align-middle form-label">PO No</label>
              <input type="text" class="form-control" :value="formData.po_number" readonly>
            </div>
            <div class="col-sm-3">
              <label class="align-middle form-label">PO Date</label>
              <input type="date" class="form-control date-mask" v-model="formData.po_date" :disabled="!shouldSave">
            </div>
            <div class="col-sm-3">
              <label class="form-label">Business Unit</label>
              <v-select
                placeholder="Select Business"
                v-model="formData.business_id"
                :options="business"
                label="name"
                :reduce="name => name.id"
                :disabled="!shouldSave"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-3">
              <label class="form-label">Cost Centre</label>
              <v-select
                placeholder="Select Cost Centre"
                v-model="formData.cost_centre_id"
                :options="costCentres"
                label="name"
                :reduce="name => name.id"
                :disabled="!shouldSave"
              />
            </div>
            <div class="col-sm-3">
              <label class="form-label">Project</label>
              <v-select
                placeholder="Select Project"
                v-model="formData.project_id"
                :options="projects"
                label="name"
                :reduce="name => name.id"
                :disabled="!shouldSave"
              />
            </div>
            <div class="col-sm-3">
              <label class="align-middle form-label">Req Reference</label>
              <input type="text" class="form-control" :disabled="!shouldSave">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="px-2">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link"
               data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
          </li>
          <li @click="formData.has_item_detail = false" class="nav-item">
            <a :class="{'active' : !formData.has_item_detail}" class="nav-link"
               data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
          </li>
        </ul>
      </div>

      <div class="px-3">
        <div v-if="!formData.has_item_detail">
          <template :key="index" v-for="(data, index) in accountsDetails">
            <ItemDetailsNewPO
              class="mt-3"
              :index="index"
              :data="data"
              :products="products"
              :currencies="currencies"
              :accountHeads="accountHeads"
              :vatRate="vatRate"
              :isItem="isItem"
              :isPO="true"
              :isPOCreate="isPOCreate"
              :isDisable="!shouldSave"
              @onClose="removeBill"
            />
          </template>
        </div>

        <div v-else>
          <template :key="index" v-for="(data, index) in itemsDetails">
            <ItemDetailsNewPO
              class="mt-3"
              :index="index"
              :data="data"
              :products="products"
              :currencies="currencies"
              :accountHeads="accountHeads"
              :vatRate="vatRate"
              :isItem="isItem"
              :isPO="true"
              :isDisable="!shouldSave"
              :isPOCreate="isPOCreate"
              @onClose="removeBill"
            />
          </template>
        </div>
      </div>

      <div class="px-2 mt-2">
        <button @click="addNewBill" class="btn btn-primary" :disabled="!shouldSave">Add line</button>
      </div>

      <div class="px-2 mb-3">
        <div class="row mt-3 px-2 justify-content-end">
          <div class="col-md-5">
            <div class="row mt-1">
              <div class="col-md-4 align-middle">SubTotal :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="subTotal" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">VAT :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="totalVAT" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">Total :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="total" readonly>
              </div>
            </div>
          </div>

          <div class="col-12 mt-3">
            <div class="mb-2">
              <label for="note" class="form-label fw-bold">Terms & Conditions:</label>
              <!-- <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2"
                        id="description"></textarea> -->
              <div class="card _terms_card">
                <div class="card-body">

                  <div
                    v-for="(term, index) in selectedTerms" :key="term"
                    class="d-flex _card_row">
                    <div class="flex-shrink-0 me-1 fw-bolder d-flex _align_item_center">
                      {{ index + 1 }}.
                    </div>
                    <div class="d-flex justify-content-between flex-grow-1 _align_item_center">
                      <div v-if="index !== currentIndex" class="me-1 _inline_input" @dblclick="editTerm(term, index)">
                        <p  class="fw-bold mb-0">{{ term }}</p>
                      </div>

                      <div v-show="index === currentIndex" class="_inline_input">
                        <textarea v-model="newTerm" @blur="onEditTerm(term, index)" class="form-control" rows="1"></textarea>
                      </div>
                      <div class="mt-sm-0 _card_action">
                        <button @click="removeItem(index)" class="btn btn-outline-danger btn-md" title="Edit PO"
                                style="cursor: pointer"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="_card_row">
                    <div class="gap-2 text-center">
                      <button
                        @click="openModal"
                        class="btn btn-outline-primary"
                        type="button"
                        :disabled="!shouldSave"
                      >
                        <i class="fas fa-plus"></i>
                        Add new
                      </button>
                    </div>
                  </div>
                  <!-- /Social Accounts -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-12 mt-2">
            <div class="mb-1">
              <label class="form-label" for="note">Comments</label>
              <textarea
                v-model="comment"
                placeholder="Comments"
                class="form-control"
                rows="1"
                :readonly="viewOnly"
              />
            </div>
          </div>
        </div>

        <div class="row mb-1 d-flex justify-content-end">
          <div
            class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2"
          >
            <button :disabled="saveButtonLoader|| (formData.approved_by_self > 0)" class="btn btn-danger" @click="changeStatus('rejected')">
              Reject
            </button>

            <button
              :disabled="saveButtonLoader|| (formData.approved_by_self > 0)"
              class="btn btn-primary"
              @click="changeStatus('returned')"
            >
              Return
            </button>

            <button
              :disabled="saveButtonLoader|| (formData.approved_by_self > 0)"
              class="btn btn-success"
              @click="changeStatus((formData.status !== 'pending_approve') ? 'pending_approve' : 'approved')"
            >
              {{getConfirmButtonName()}}
            </button>
            <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
            <button @click="$refs.viewApprovalMatrix.updateShowApprovalMatrix(true)" class="btn btn-warning">Show More</button>
          </div>
        </div>

      </div>
    </div>
    <view-approval-matrix-details
      :workflowDetails="workflowDetails"
      :approvalLogs="formData.workflow_approval_logs ?? []"
      ref="viewApprovalMatrix"
    />
    <GlobalLoader/>
    <TermsModal
      v-if="store.state.isModalOpen"
      :modalTitle="modalTitle"
      :isEdit="isEdit"
      :formData="modalFormData"
      @onSubmit="handleSubmit"
      @onClose="clearModal"
      :preAddedTerms="preAddedTerms"
      :selectedTerms="selectedTerms"
      ref="termsModalRef"
    />
  </div>
</template>


<script setup>
import TitleButton from '@/components/atom/TitleButton'

import {
  inject,
  ref,
  onMounted,
  computed,
  watch,
  reactive
}                         from 'vue';
import {
  useRoute,
  useRouter
}                         from "vue-router";
import {useStore}         from 'vuex';
import handleRequisitions from "@/services/modules/procurement/requisition";
import handlePurchase     from '@/services/modules/purchase'
import handleContact      from '@/services/modules/contact'
import ItemDetailsNewPO   from '@/components/molecule/procurement/purchase-order/purchase-order-entry/ItemDetailsNewPO.vue'
import TermsModal from '@/components/molecule/procurement/purchase-order/purchase-order-entry/TermsModal.vue'
import {vatRate}          from '@/data/inventory.js'
import handleCBusinesses  from '@/services/modules/businesses'
import handleCostCentres  from "@/services/modules/ngo/costCentre";
import handleProjects     from "@/services/modules/procurement/project";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import ViewApprovalMatrixDetails from "@/components/molecule/approval-matrix/ViewApprovalMatrixDetails.vue";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

const router      = useRouter()
const route       = useRoute()
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore()

const termsModalRef = ref(null);
const isEdit = ref(false)
const modalTitle = ref('Add PI Receiving')
const modalFormData = reactive({});
const newTerm = ref('');
const currentIndex = ref(-1);
const workflowDetails = ref({});
const viewApprovalMatrix = ref(null);


const selectedTerms = reactive([
  'Return on Investment',
  'Business-to-Business',
  'Business-to-Consumer',
  'Break-even Point',
  'Supply Chain',
  'Initial Public Offering'
]);

const editTerm = (term, index) => {
  currentIndex.value = index;
  newTerm.value = term;
  // console.log(inputRefs.value[index])
  // inputRefs.value[index]?.focus();
}

const onEditTerm = (item, index) => {
  selectedTerms.splice(index,  1, newTerm.value);
  currentIndex.value = -1;
}
const preAddedTerms = reactive([
  { id: 1, value: 'Return on Investment' },
  { id: 2, value: 'Key Performance Indicators' },
  { id: 3, value: 'SWOT Analysis' },
  { id: 4, value: 'Business-to-Business' },
  { id: 5, value: 'Business-to-Consumer' },
  { id: 6, value: 'Return on Purchase' },
  { id: 7, value: 'Break-even Point' },
  { id: 8, value: 'Supply Chain' },
  { id: 9, value: 'Customer Relationship Management' },
  { id: 10, value: 'Return on Sale' },
  { id: 11, value: 'Initial Public Offering' }
]);

const removeItem = (index) => {
  selectedTerms.splice(index, 1)
}
const openModal = () => {
  modalTitle.value = `Add Terms and Conditions`
  store.state.isModalOpen = true;
}

const handleSubmit = () => {

}

const clearModal = () => {

}

const {fetchProductList, fetchAccountHead, fetchCurrencyList, ...rest} = handlePurchase()
const {
  fetchPONumber,
  fetchSinglePO,
  createNewPO,
  updatePOStatus,
} = handleRequisitions();
const {fetchContactProfiles} = handleContact()
const {fetchWorkflowFromPageId} = handleWorkflow()

const companyId   = computed(() => {
  return route.params.companyId
})
const {fetchBusinessList} = handleCBusinesses()
const { fetchCostCentreList } = handleCostCentres()
const { fetchProjects } = handleProjects()
let headerTitle   = ref('Create Purchase Order')
let loader        = ref(false)
let productLoader = ref(false)
let saveButtonLoader = ref(false)
let products      = ref([])
let currencies    = ref([])
let accountHeads  = ref([])
let requisition   = ref({})
let supplier      = ref(null)
let user          = ref(null)
let comment          = ref(null)
let poEditData    = ref({})
let business      = ref([])
let costCentres   = ref([])
let projects      = ref([])
let isPOCreate    = true
let formData      = ref({
  supplier_id        : null,
  po_number          : null,
  po_date            : null,
  requisition_details: [],
  subtotal_amount    : 0.00,
  vat_amount         : 0.00,
  total_amount       : 0.00,
  has_item_detail    : true,
  description        : '',
  item_details       : [
    {
      product_id  : null,
      quantity    : null,
      currency    : 'BDT',
      rate        : null,
      amount      : null,
      vat         : 0,
      vat_amount  : null,
      total_amount: null,
      description : null,
    }
  ],
  account_details    : [
    {
      account_head_id: null,
      currency       : 'BDT',
      amount         : null,
      vat            : 0,
      vat_amount     : null,
      total_amount   : null,
      description    : null,
    }
  ],
})
let contacts                 = ref([])
let supplier_id              = ref(null)


const hasItemDetails = computed(() => {
  return formData.value.has_item_detail
})

watch(hasItemDetails, (newValue) => {
  if(!newValue) {
    delete formData.value.item_details
    formData.value.account_details = [{
      account_head_id: null,
      currency       : 'BDT',
      amount         : null,
      vat            : 0,
      vat_amount     : null,
      total_amount   : null,
      description    : null,
    }]
  }
  if(newValue) {
    delete formData.value.account_details
    formData.value.item_details = [{
      product_id  : null,
      quantity    : null,
      currency    : 'BDT',
      rate        : null,
      amount      : null,
      vat         : 0,
      vat_amount  : null,
      total_amount: null,
      description : null,
    }]
  }
})

function onPageReload() {
  loader.value         = true
  const productRes     = fetchProductList(getQuery())
  const accountHeadRes = fetchAccountHead(getQuery())
  const companyQuery = `?company_id=${companyId.value}`
  const projectQuery = companyQuery + '&with_donors=1';
  const contactRes   = fetchContactProfiles(companyQuery)
  const currencyRes  = fetchCurrencyList(getQuery())
  const businessRes = fetchBusinessList(companyQuery)
  const costCenterRes = fetchCostCentreList(companyQuery)
  const projectRes = fetchProjects(projectQuery)
  const workflowQuery = getQuery() + `&approval_list_page_id=${route.params.pageId}`;

  Promise.all([
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
    productRes.then(res => {
      if (res.data) products.value = res.data
    }),
    currencyRes.then(res => {
      if (res.data) currencies.value = res.data
    }),
    businessRes.then(res=> {
      if(res.data) business.value = res.data
    }),
    costCenterRes.then(res=> {
      if(res.data) costCentres.value = res.data
    }),
    projectRes.then(res=> {
      if(res.data) projects.value = res.data
    }),
    accountHeadRes.then(res => {
      if (res.data) accountHeads.value = res.data
    }),
    fetchWorkflowFromPageId(workflowQuery).then(res => {
      if(res.data) workflowDetails.value = res.data
    }),
    prepareCreateUpdatePageData()
  ])
    .then(() => {
      loader.value = false;
    })
    .catch((err) => {
      loader.value = false
    })
}

function changeStatus(status){
  if(status === 'approved' && ! confirm('Are you sure you want to approve this purchase order')){
    return;
  }
  saveButtonLoader.value = true

  let statusData = {
    'status' : status,
    'workflow_master_id' : workflowDetails.value.id,
    'comment' : comment.value,
  }
  updatePOStatus(route.query.poId, getQuery(), statusData)
    .then(res => {
      saveButtonLoader.value = false
      if(res.status) {
        showSuccess(res.message)
        navigateToListPage();
      }
      if(!res.status) {
        showError(res.message)
      }
    })
    .catch(err=> {
      saveButtonLoader.value = false;
      showError(err)
    })
}

async function prepareCreateUpdatePageData() {
  try {
    if(route.query.poId)
    {
      const pOResp = await fetchSinglePO(route.query.poId, getQuery());
      poEditData.value = pOResp ? pOResp.data : {};
      setFormData()
      isPOCreate = false;
      return;
    }
    // Generate PO number
    formData.value.po_number = await fetchPONumber(getQuery());
    formData.value.po_date = new Date().toISOString().split('T')[0];
  } catch (e) {}
}

function setFormData() {
  headerTitle.value          = 'Approve PO';
  supplier.value             = poEditData.value.contact_profile ? poEditData.value.contact_profile.full_name: null;
  formData.value.supplier_id = poEditData.value.contact_profile_id ?? null;
  formData.value.po_date     = poEditData.value.po_date;
  formData.value.po_number   = poEditData.value.po_number;
  formData.value.description = poEditData.value.description;
  formData.value.workflow_approval_logs = poEditData.value.workflow_approval_logs;
  formData.value.status = poEditData.value.status;

  delete formData.value.item_details;
  delete formData.value.account_details;
  formData.value.item_details        = [];
  formData.value.account_details     = [];
  formData.value.requisition_details = [];
  formData.value.cs_details          = [];

  for(const item of poEditData.value.purchase_general) {
    if (item.is_product === '1') {
      formData.value.item_details.push(item);
      continue;
    }
    item.account_head_id = item.cs_general.requisition_account.account_head_id;
    formData.value.account_details.push(item);
  }
}

function removeBill(index) {
  if(hasItemDetails.value) {
    formData.value.item_details.splice(index, 1)
  }
  if(!hasItemDetails.value) {
    formData.value.account_details.splice(index, 1)
  }
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function onClickCloseButton() {
  delete route.query.poId;
  router.push({name: `po-list`, params: route.params, query: route.query});
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function navigateToListPage() {
  delete route.query.poId;
  delete route.query.selected_requisitions;
  router.push({name: `po-approval`, params: route.params, query: route.query});
}

const getConfirmButtonName = () => {
  if(formData.value.approved_by_self > 0) {
    return 'Already Approved';
  }

  if(formData.value.status === 'pending_approve') {
    return 'Approve';
  }

  return "Confirm"
}

const shouldSave = computed( () => {
  return false;
})

function addNewBill() {
  if(hasItemDetails.value) {
    formData.value.item_details.push({
      product_id  : null,
      quantity    : null,
      currency    : 'BDT',
      rate        : null,
      amount      : null,
      vat         : 0,
      vat_amount  : 0,
      total_amount: null,
      description : null,
    })
  }
  if(!hasItemDetails.value) {
    formData.value.account_details.push({
      account_head_id: null,
      currency       : null,
      amount         : null,
      vat            : null,
      vat_amount     : null,
      total_amount   : null,
      description    : null,
    })
  }
}

const itemsDetails    = computed(() => {
  return formData.value.item_details
})
const accountsDetails = computed(() => {
  return formData.value.account_details
})
const isItem = computed(() => {
  return !!formData.value.has_item_detail
})
const subTotal        = computed(() => {
  let subTotal = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      subTotal += parseInt(item.quantity * item.rate)
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.amount) {
        subTotal += parseInt(item.amount)
      }
    })
  }

  return subTotal
})
const totalVAT = computed(() => {
  let vat = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if (item.vat_amount) {
        vat += parseInt(item.vat_amount)
      }
    })
    return vat.toFixed(2);
  }

  formData.value.account_details.map(item => {
    if (item.vat_amount) {
      vat += parseInt(item.vat_amount)
    }
  })
  return vat.toFixed(2)
})
const total           = computed(() => {
  return parseInt(subTotal.value) + parseInt(totalVAT.value)
})

onMounted(() => {
  onPageReload();
})
</script>


<style>
._card_action button{
  border: none !important;
}

._align_item_center{
  align-items: center;
}

._inline_input{
  width: 80%
}

._terms_card{
  width: 80%;
}

._card_row:last-child {
  border: none;
}

._card_row{
  border-color: #e5e5e5;
  border-style: solid;
  padding-bottom: 2%;
  margin-bottom: 1%;
  border-width: 0;
  border-bottom-width: 1px;
}
hr {
  margin: 1rem 0;
  color: #403d4452 !important;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}
</style>