<template>
    <div class="modal fade show" aria-labelledby="addNewCardTitle"
        style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ modalTitle }}</h1>
                            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">

                <div class="modal-body">

                    <div class="row gy-2 px-2">

                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    Select terms and conditions
                                </div>
                                <div class="card-body _modal_card">
                                    <button 
                                        v-for="(term, index) in preAddedTerms" :key="term.id" 
                                        @click="onClick(term, index)"
                                        class="btn cancel-subscription mt-1 waves-effect round" 
                                        :class="term.isSelected ? 'btn-primary' : 'btn-outline-primary'">
                                        <i class="fas fa-check"></i>
                                        {{ term.value }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    Or Add new
                                </div>
                                <div class="card-body row">
                                    <div class="col-8">
                                        <input v-model.trim="newValue" type="text" class="form-control">
                                    </div>

                                    <div class="col-4 text-left">
                                        <button :disabled="!Boolean(newValue)" type="button" @click="submit" class="btn btn-primary me-1 waves-effect waves-float waves-light">
                                            <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <i class="fas fa-plus"></i>
                                            {{ 'Add new' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    </div>
</template>

<script setup>

import { onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
    preAddedTerms: [],
    selectedTerms: [],
    formData: Object,
    modalTitle: {
        type: String,
        default: 'Add Tag Register'
    },
    isEdit: {
        type: Boolean,
        default: false
    }
})

const newTerms = reactive([]);
const $emit = defineEmits(['onSubmit', 'onClose'])

let isOpen = ref(false)
const loading = ref(false)
const schema = ref({})
const newValue = ref('');

const onClick = (term, index) => {
    const newIndex = newTerms.findIndex(item => item === term.value);
    if (newIndex !== -1) {
        newTerms.splice(newIndex, 1);
    }
    if (newIndex < 0) {
        newTerms.push(term.value)
    }
    term.isSelected = !term.isSelected;
    saveTerms();
}

const setInitialValue = () => {
    newNotes.length = 0;
    newNotes.push(...props.note.selectedNotes);
}

setInitialValue();


const closeModal = () => {
    store.state.isModalOpen = false;
}

const saveTerms = () => {
    props.selectedTerms.length = 0;
    props.selectedTerms.push(...newTerms)
}

const submit = () => {
    saveTerms();
    if (newValue.value) {
        props.selectedTerms.push(newValue.value)
    }
    closeModal();
}

defineExpose({loading })

</script>


<style>
._modal_card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
</style>